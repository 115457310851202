import React, { useEffect, useState } from 'react'
import patientPlotStyle from 'style/patientBoxplotStyle'
import fetchStream from 'functions/bff/fetchStream'
import prepareBoxPlots from 'functions/plot/prepareBoxPlots'
import boxPlot from 'functions/plot/boxPlot'
import gridBox from 'functions/gridBox'

function PatientBoxplot(props) {
    const classes = patientPlotStyle()
    const [boxplots, setBoxplots] = useState([])
    const [RangeTop, setRangeTop] = useState(200)
    const [graphProps, setGraphProps] = useState([])

    const handleBoxPlotClick = (event) => {
        props.setPatientView({...props.patientView, selectedView: 'overview'})
    }

    const handleBoxPlotHover = (event) => {
    }

    useEffect(() => {
        if (props.recordings.length) {
            async function fetchStreams() {
                var stream = []
                for (let index = 0; index < props.recordings.length; index++) {
                    let recording = props.recordings[index]
                    let fetch = await fetchStream({ group: props.patient.group, recording: recording.id, signal: 'RPEAK2', start: 0, end: 0 }, props.token)
                    stream = stream.concat(fetch.map(element => element + Math.floor(recording.startDate * 250) / 250))
                }
                setBoxplots(prepareBoxPlots(stream, props.patient, props.patientView.boxplot))
            }
            fetchStreams()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.recordings, props.patientView])

    useEffect(() => {
        if (boxplots.streams) {
            setGraphProps({ 
                height: props.graphSize.height,
                width: props.graphSize.width,
                xGrid: boxplots.streams.length,
                yGrid: Math.round(props.graphSize.height / props.graphSize.width * boxplots.streams.length),
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [boxplots, props.graphSize])

    return (
        <div className={classes.root}>
            {graphProps.length !== 0
                && <div>
                    {gridBox(boxplots, graphProps)}
                    {boxPlot(boxplots, graphProps, RangeTop, handleBoxPlotClick, handleBoxPlotHover)}
                </div>
            }
        </div>
    )
}

export default PatientBoxplot
