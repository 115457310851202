import React, { useState, useEffect, useRef } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import { withAuthenticator } from 'aws-amplify-react'
import appConfig from 'config/appConfig'
import fetchStudyList from 'functions/bff/fetchStudyList'
import authenticateCloud from 'functions/cloud/authenticate'
import Content from "tree/Content"
import Appbar from "tree/AppBar"
import DrawerLeft from "tree/DrawerLeft"

function App() {
  const [open, setOpen] = useState(true)
  const [token, setToken] = useState('')
  const drawer = useRef(appConfig.drawerWidth.open)
  const [windowSize, setWindowSize] = useState({
    width: 0, height: 0, grid: 0, gridWidth: 0, open: true
  })
  const [studies, setStudies] = useState({
    studyList: [], selectedStudy: -2, selectedPatient: -2,
  })

  const handleDrawer = () => { setOpen(open => !open) }

  const updateWindowDimensions = () => {
    setWindowSize({
      width: window.innerWidth - drawer.current,
      height: window.innerHeight,
      grid: (window.innerHeight - 60) / (window.innerWidth - drawer.current) * appConfig.gridNumber,
      gridWidth: (window.innerWidth - drawer.current) / appConfig.gridNumber,
      open: open
    })
  }

  useEffect(() => {
    updateWindowDimensions()
    window.addEventListener('resize', updateWindowDimensions, false)
    return function cleanup() {
      window.removeEventListener('resize', updateWindowDimensions)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const auth = () => {
    authenticateCloud().then((response) => {
      setToken(response)
    })
  }

  useEffect(() => {
    auth()
    setInterval(auth, 45 * 30 * 1000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (token.length) {
      fetchStudyList(token).then(data => {
        setStudies({ studyList: data.studies, selectedStudy: -1, selectedPatient: -1 })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  useEffect(() => {
    drawer.current = open ? appConfig.drawerWidth.open : appConfig.drawerWidth.close
    updateWindowDimensions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <div style={{ display: 'flex' }}>
      <CssBaseline />
      <Appbar />
      <DrawerLeft
        studies={studies}
        setStudies={setStudies}
        windowSize={windowSize}
        drawerCallback={handleDrawer} />
      <Content
        token={token}
        studies={studies}
        setStudies={setStudies}
        windowSize={windowSize} />
    </div>
  )
}

export default withAuthenticator(App)