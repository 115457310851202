import { makeStyles } from '@material-ui/core/styles'
import appConfig from 'config/appConfig'

const patientGridStyle = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
    overflow: 'auto',
    maxHeight: 400,
  },
  paperPatients: {
    position: 'relative',
    top: -1,
    boxShadow: 'none',
    borderRadius: '0px 0px 5px 5px',
    padding: theme.spacing(2),
  },
  paper: {
    height: 50,
    width: 240,
    boxShadow: 'none',
    borderRadius: '5px 5px 5px 5px',
  },
  title: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
    marginLeft:20,
  },
  icon: {
    width: 20,
    marginTop: 10,
    marginLeft: 20,
    verticalAlign: 'middle',
  },
  button: {
    height: 50,
    width: 240,
    fontSize: 14,
    fontWeight: 500,
    boxShadow: 'none',
    justifyContent: 'flex-start',
    borderRadius: '5px 5px 5px 5px',
    color: '#000000',
    backgroundColor: '#FFFFFF',
    border: `1px solid #E0E0E0`,
   '&:hover': {
       color: '#FFFFFF',
       backgroundColor: appConfig.colors.blue,
       border: `1px solid ${appConfig.colors.blue}`,
   }
  },
  newButton: {
    height: 50,
    width: 240,
    fontSize: 14,
    fontWeight: 500,
    boxShadow: 'none',
    justifyContent: 'flex-start',
    borderRadius: '5px 5px 5px 5px',
    color: '#000000',
    backgroundColor: appConfig.colors.secondaryColor,
    border: `1px solid #E0E0E0`,
   '&:hover': {
       color: '#FFFFFF',
       backgroundColor: appConfig.colors.blue,
       border: `1px solid ${appConfig.colors.blue}`,
   }
  },
  dialogButton:{
    color: appConfig.colors.blue,
  },
  dialogTextField:{
    color: appConfig.colors.blue,
    marginBottom:20,
    marginRight:40,
  },
  dialogIcon:{
    marginTop:20,
    marginRight:20,
    marginBottom:20

  },
}))

export default patientGridStyle