import dataApi from 'config/dataApi'

function addStudy(study, token) {
    const url = `${dataApi.api_url}add_study`
    return new Promise((resolve, reject) => {
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
            },
            body: JSON.stringify(study)
        }).then(response => response.json()).then(data => resolve(data))
    })
}
export default addStudy