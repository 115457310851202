import { makeStyles } from '@material-ui/core/styles'
const contentStyle = makeStyles((theme) => ({
  toolbar: {
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 0,
  },
}))

export default contentStyle