import { makeStyles } from '@material-ui/core/styles'
import appConfig from '../config/appConfig'

const studyDescriptionStyle = makeStyles((theme) => ({
    root: {
    },
    paper: {
        position:'relative',
        height: 240,
        marginTop: 10,
        boxShadow: 'none',
        borderRadius: '5px 5px 0px 0px',
        backgroundColor: appConfig.colors.secondaryColor,
    },
    descriptionTitle: {
        marginTop: 20,
        marginLeft:20,
        fontSize: theme.typography.pxToRem(12),
        color: '#777777'
    },
    description: {
        display: 'flex',
        position:'relative',
        overflow: 'auto',
        maxHeight: 170,
        marginLeft: 20,
        marginTop: 10,
        marginRight: 20,
        textAlign: 'justify',
        fontSize: theme.typography.pxToRem(14),
    },
    studyInfo:{
        width: 280,
    },
}))

export default studyDescriptionStyle