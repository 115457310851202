import dataApi from 'config/dataApi'

function addPatient(patient, token) {
    const url = `${dataApi.api_url}add_patient`
    return new Promise((resolve, reject) => {
        fetch(url, {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': token,
            },
            body: JSON.stringify(patient)
        }).then(response => response.json()).then(data => resolve(data))
    })
}
export default addPatient