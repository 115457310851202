import dataApi from 'config/dataApi'

function fetchStream(stream, token) {
    const url = `${dataApi.api_url}stream`
    return new Promise((resolve, reject) => {
        fetch(url, {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': token,
            },
            body: JSON.stringify(stream),
        }).then(response => response.json().then(data => {
            resolve(JSON.parse(data.channels[0]))
        }))
    })
}
export default fetchStream
