import { makeStyles } from '@material-ui/core/styles'
import appConfig from 'config/appConfig'

const appBarStyle = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        background: appConfig.colors.primaryColor,
        zIndex: theme.zIndex.drawer + 1,
    },
    title: {
        flexGrow: 1,
    },
    userName: {
        marginRight: theme.spacing(2),
        fontSize: '15px',
    },
    image: {
        width:40,
        height:40, 
        marginRight: 20,
    },
}))

export default appBarStyle