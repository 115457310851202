import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

function DockIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d="M12 0a12 12 0 1012 12A12 12 0 0012 0zM3.12 9.61l.29-.89a1.5 1.5 0 011.89-1l2.26.74a1.5 1.5 0 011 1.89l-.29.89a1.49 1.49 0 01-1.89 1l-2.3-.74a1.5 1.5 0 01-.96-1.89zm7.36 7.84l-1.41 1.93a1.49 1.49 0 01-2.07.33l-.76-.55a1.5 1.5 0 01-.33-2.1l1.4-1.93a1.5 1.5 0 012.1-.33l.75.55a1.51 1.51 0 01.32 2.1zm1-9.25A1.5 1.5 0 0110 6.7V4.31a1.5 1.5 0 011.5-1.5h.94A1.5 1.5 0 0114 4.31V6.7a1.5 1.5 0 01-1.5 1.5zm6.25 11l-.76.55a1.49 1.49 0 01-2.09-.33l-1.41-1.93a1.51 1.51 0 01.34-2.1l.75-.55a1.5 1.5 0 012.1.33l1.4 1.93a1.5 1.5 0 01-.28 2.06zm2.14-7.66l-2.27.73a1.49 1.49 0 01-1.89-1l-.29-.89a1.5 1.5 0 011-1.89l2.26-.74a1.5 1.5 0 011.89 1l.29.89a1.5 1.5 0 01-.94 1.86z" data-name="Layer 1" />
        </SvgIcon>
    )
}

export default DockIcon