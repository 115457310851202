import React from 'react'
import Typography from '@material-ui/core/Typography'
import appConfig from 'config/appConfig'

function gridBox(sig, graph) {

    const width = graph.width - 100
    const height = graph.height - 50
    
    const vlines1 = []
    for (let i = 1; i < graph.xGrid * 5; i++) { vlines1.push(i * width / graph.xGrid / 5 - 1) }
    const vlines2 = []
    for (let i = 1; i < graph.xGrid; i++) { vlines2.push(i * width / graph.xGrid - 1) }
    const hlines1 = []
    for (let i = 1; i < graph.yGrid * 5; i++) { hlines1.push(i * height / graph.yGrid / 5 - 1) }
    const hlines2 = []
    for (let i = 1; i < graph.yGrid; i++) { hlines2.push(i * height / graph.yGrid - 1) }

    return (
        <div>
            {vlines1.map((vline) => (
                <div key={`vline1${vline}`} style={{position: 'absolute', borderLeft: `1px solid ${appConfig.colors.secondaryColor}`, left: vline, height: height - 2}}></div>
            ))}
            {hlines1.map((hline) => (
                <div key={`hline1${hline}`} style={{position: 'absolute', borderTop: `1px solid ${appConfig.colors.secondaryColor}`, top: hline, width: width - 2}}></div>
            ))}
            {vlines2.map((vline) => (
                <div key={`vline2${vline}`} style={{position: 'absolute', borderLeft: '1px solid', borderColor: '#E0E0E0', left: vline, height: height - 2}}></div>
            ))}
            {hlines2.map((hline) => (
                <div key={`hline2${hline}`} style={{position: 'absolute', borderTop: '1px solid', borderColor: '#E0E0E0', top: hline, width: width - 2}}></div>
            ))}
            <Typography style={{position:'absolute', width:width/graph.xGrid, top: height+5, left: 0,  fontSize: 12, textAlign: 'center'}}>{sig.names[0]}</Typography>
            {vlines2.map((vline,index) => (
                <Typography key={`text${vline}`} style={{position:'absolute', width:width/graph.xGrid, top: height+5, left: vline,  fontSize: 12, textAlign: 'center'}}>{sig.names[index+1]}</Typography>
            ))}
            
        </div>
        
    )
}

export default gridBox