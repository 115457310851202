import React from 'react'
import Typography from '@material-ui/core/Typography'
import patientIcon from 'files/patientIcon.png'
import centerIcon from 'files/centerIcon.png'
import clockIcon from 'files/clockIcon.png'
import dataIcon from 'files/dataIcon.png'

const studyInfo = (study) => {
    return (
        <table style={{ marginTop: 10 }}><tbody>
            <tr>
                <td>
                    <img style={{width:30,marginTop:10,marginLeft:20,verticalAlign:'middle'}} src={patientIcon} alt="patientIcon" />
                </td>
                <td>
                    <Typography style={{fontSize: 12,color: '#777777',marginTop:10,marginLeft:20,}}>
                        Patients
                        </Typography>
                    <Typography style={{fontSize: 14,marginLeft:20,}}>
                        {study.patients.length} enrolled / {study.size} total
                        </Typography>
                </td>
            </tr>
            <tr>
                <td>
                    <img style={{width:30,marginTop:10,marginLeft:20,verticalAlign:'middle'}} src={centerIcon} alt="centerIcon" />
                </td>
                <td>
                    <Typography style={{fontSize: 12,color: '#777777',marginTop:10,marginLeft:20,}}>
                        Center
                        </Typography>
                    <Typography style={{fontSize: 14,marginLeft:20,}}>
                        {study.centers}
                    </Typography>
                </td>
            </tr>
            <tr>
                <td>
                    <img style={{width:30,marginTop:10,marginLeft:20,verticalAlign:'middle'}} src={clockIcon} alt="clockIcon" />
                </td>
                <td>
                    <Typography style={{fontSize: 12,color: '#777777',marginTop:10,marginLeft:20,}}>
                        Period
                            </Typography>
                    <Typography style={{fontSize: 14,marginLeft:20,}}>
                        June 2020 - October 2020
                            </Typography>
                </td>
            </tr>
            <tr>
                <td>
                    <img style={{width:30,marginTop:10,marginLeft:20,verticalAlign:'middle'}} src={dataIcon} alt="dataIcon" />
                </td>
                <td>
                    <Typography style={{fontSize: 12,color: '#777777',marginTop:10,marginLeft:20,}}>
                        Data
                            </Typography>
                    <Typography style={{fontSize: 14,marginLeft:20,}}>
                        20 patient days / 700 hours
                            </Typography>
                </td>
            </tr>
        </tbody></table>
    )
}

export default studyInfo