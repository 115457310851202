import React from 'react'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import appConfig from 'config/appConfig'
import recordingListStyle from 'style/recordingListStyle.js'

function RecordingList(props) {
  const classes = recordingListStyle()

  return (
    <div style={{ position: 'absolute', top: window.innerHeight - 80, height: 80, width: appConfig.drawerWidth.open, backgroundColor: appConfig.colors.secondaryColor }}>
        <Divider />
      <Typography className={classes.title} >
        Recordings
      </Typography>
    </div>
  )
}

export default RecordingList