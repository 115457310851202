import { makeStyles } from '@material-ui/core/styles'
import appConfig from '../config/appConfig'

const studyGridItemStyle = makeStyles((theme) => ({
  paper: {
    height: 240,
    width: 300,
    boxShadow: 'none',
    borderRadius: '5px 5px 0px 0px',
    backgroundColor: appConfig.colors.secondaryColor,
  },
  paperHighlight:{
   borderColor:appConfig.colors.blue

  },
  paperNoHighlight:{
  },
  paperTitle: {
    position:'relative',
    height: 60,
    top:-1,
    width: 300,
    boxShadow: 'none',
    borderRadius: '0px 0px 5px 5px',
  },
  paperTitleHighlight:{
    backgroundColor:appConfig.colors.blue,
    borderColor:appConfig.colors.blue,
    color:'#FFFFFF'
  },
  paperTitleNoHighlight:{
    backgroundColor:'#FFFFFF',
  },

  title: {
    fontSize: theme.typography.pxToRem(14),
    textAlign: 'center',
    fontWeight: 500,
    marginTop:10,
  },
  status: {
    fontSize: theme.typography.pxToRem(12),
    textAlign: 'center',
    fontWeight: 500,
    marginTop:2.5,
  },
}))

export default studyGridItemStyle