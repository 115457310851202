import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import LibraryAddIcon from '@material-ui/icons/LibraryAdd'
import addStudyDialogStyle from 'style/addStudyDialogStyle'
import addStudy from 'functions/bff/addStudy'
import fetchStudyList from 'functions/bff/fetchStudyList'

function AddStudyDialog(props) {
    const classes = addStudyDialogStyle()
    const [studyID, setStudyID] = useState('')
    const [studyDescription, setStudyDescription] = useState('')
    const [studyTitle, setStudyTitle] = useState('')
    const [studyCenter, setStudyCenter] = useState('')
    const [studySize, setStudySize] = useState('')
    const [studyStatus, setStudyStatus] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [studyGroup, setStudyGroup] = useState('')

    const handleAddStudy = async () => {
        props.setOpen(false)
        if (studyID !== '') {
            var study = {
                title: studyTitle,
                id: studyID,
                description: studyDescription,
                centers: studyCenter,
                size: studySize,
                status: studyStatus,
                startDate: startDate,
                endDate: endDate,
                group: studyGroup,
            }
            await addStudy(study,props.token)
            let data = await fetchStudyList(props.token)
            props.setStudies({ ...props.studies, studyList: data.studies })
        }
    }

    return (
        <Dialog open={props.open} onClose={() => { props.setOpen(false) }} maxWidth='lg' fullWidth={true}>
            <DialogTitle>Add a new study</DialogTitle>
            <DialogContent >
                <DialogContentText style={{ marginBottom: 20 }}>
                    To add a new study, please enter a unique, unidentified studyId, which will be used to link your study the Sensor Dot database. In addition, fill in all the information you already know about your study below.
                </DialogContentText>
                <Grid container spacing={2} alignItems="flex-end">
                    <Grid item>
                        <LibraryAddIcon />
                    </Grid>
                    <Grid item>
                        <TextField
                            style={{ minWidth: 200 }}
                            autoComplete="off"
                            label="studyID"
                            value={studyID}
                            onChange={event => setStudyID(event.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid style={{ padding: 10 }} container spacing={2} justify="space-between">
                    <TextField
                        style={{ marginTop: 20, width: 200 }}
                        label="study title"
                        value={studyTitle}
                        onChange={(event) => { setStudyTitle(event.target.value) }}
                    />
                    <TextField
                        style={{ marginTop: 20, width: 200 }}
                        label="study center"
                        value={studyCenter}
                        onChange={(event) => { setStudyCenter(event.target.value) }}
                    />
                    <TextField
                        style={{ marginTop: 20, width: 200 }}
                        label="number of patients"
                        value={studySize}
                        onChange={(event) => { setStudySize(event.target.value) }}
                    />
                </Grid>
                <Grid style={{ padding: 10 }} container spacing={2} justify="space-between">
                    <TextField
                        style={{ marginTop: 20, width: 200 }}
                        label="study status"
                        value={studyStatus}
                        onChange={(event) => { setStudyStatus(event.target.value) }}
                    />
                    <TextField
                        style={{ marginTop: 20, width: 200 }}
                        label="start date"
                        value={startDate}
                        onChange={(event) => { setStartDate(event.target.value) }}
                    />
                    <TextField
                        style={{ marginTop: 20, width: 200 }}
                        label="end date"
                        value={endDate}
                        onChange={(event) => { setEndDate(event.target.value) }}
                    />
                    <TextField
                    style={{ marginTop: 20, width: 200 }}
                    label="study group"
                    value={studyGroup}
                    onChange={(event) => { setStudyGroup(event.target.value) }}
                />
                </Grid>
                <Grid>
                    <TextField
                        fullWidth
                        style={{ marginTop: 20 }}
                        label="study description"
                        multiline
                        value={studyDescription}
                        onChange={(event) => { setStudyDescription(event.target.value) }}
                    />
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { props.setOpen(false) }} className={classes.dialogButton}>
                    Cancel
                </Button>
                <Button onClick={handleAddStudy} className={classes.dialogButton}>
                    Add
                </Button>
            </DialogActions>
        </Dialog >
    )
}

export default AddStudyDialog
