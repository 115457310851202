import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'
import studyGridStyle from 'style/studyGridStyle'
import StudyGridItem from './StudyGrid/StudyGridItem'
import AddStudyDialog from './StudyGrid/AddStudyDialog'

function StudyGrid(props) {
    const classes = studyGridStyle()
    const [openDialog, setOpenDialog] = useState(false)

    const handleStudySelect = (study) => (event) => {
        props.setStudies({ ...props.studies, selectedStudy: study, selectedPatient: -1 })
    }

    return (
        <div>
            <Grid container justify='space-evenly' className={classes.root}>
                {props.studies.studyList.map((study, i) => (
                    <Grid className={classes.grid} key={`studyGrid${i}`} item onClick={handleStudySelect(i)}>
                        <StudyGridItem study={study} />
                    </Grid>
                ))}
                <Grid className={classes.grid} key='newGrid' item >
                    <IconButton className={classes.button} onClick={() => {setOpenDialog(true)}}>
                        <AddIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <AddStudyDialog 
                open = {openDialog} 
                setOpen = {setOpenDialog} 
                studies = {props.studies} 
                setStudies = {props.setStudies}
                token = {props.token}
            />
        </div>
    )
}

export default StudyGrid
