import React from 'react'
import Typography from '@material-ui/core/Typography'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Link from '@material-ui/core/Link'
import studyListStyle from 'style/studyListStyle'

function StudyBreadcrumbs(props){
    
    const classes = studyListStyle()
    
    const handleBreadcrumb = (breadcrumb) => (event) => {
      event.preventDefault();
      if (breadcrumb === 'home') {
        props.setStudies({...props.studies, selectedStudy: -1, selectedPatient: -1})
      }
      if (breadcrumb === 'study') {
        props.setStudies({...props.studies, selectedPatient: -1})
      }
    }

    return (
        <div>
        {props.studies.selectedStudy < 0
            ? <Breadcrumbs key='breadcrumb1' className={classes.breadcrumbs}>
                <Typography className={classes.breadcrumbFinal}>
                  Studies
                </Typography>
              </Breadcrumbs>
            : [
              props.studies.selectedPatient === -1
              ? <Breadcrumbs key='breadcrumb2' className={classes.breadcrumbs}>
                  <Link color="inherit" href="/" onClick={handleBreadcrumb('home')}>
                    Studies
                  </Link>
                  <Typography className={classes.breadcrumbFinal}>
                    {props.studies.studyList[props.studies.selectedStudy].id}
                  </Typography>
                </Breadcrumbs>
              : <Breadcrumbs key='breadcrumb3' className={classes.breadcrumbs}>
                  <Link color="inherit" href="/" onClick={handleBreadcrumb('home')}>
                    Studies
                  </Link>
                  <Link color="inherit" href="/" onClick={handleBreadcrumb('study')}>
                    {props.studies.studyList[props.studies.selectedStudy].id}
                  </Link>
                  <Typography className={classes.breadcrumbFinal}>
                    {props.studies.studyList[props.studies.selectedStudy].patients[props.studies.selectedPatient].id}
                  </Typography>
                </Breadcrumbs>
            ]}
            </div>
    )
}

export default StudyBreadcrumbs