import React from 'react'
import loadImage from 'functions/loadImage'
import contentStyle from 'style/contentStyle'
import StudyGrid from './Content/StudyGrid'
import StudyDetail from './Content/StudyDetail'
import PatientDetail from './Content/PatientDetail'

function Content(props) {
  const classes = contentStyle()

  return (
    <main className={classes.content} >
      <div className={classes.toolbar} />
      {props.studies.selectedStudy === -2
        && loadImage(props.windowSize)
      }
      {props.studies.selectedStudy === -1
        && <StudyGrid
          studies={props.studies}
          setStudies={props.setStudies}
          windowSize={props.windowSize}
          token = {props.token}
        />
      }
      {props.studies.selectedStudy > -1 && props.studies.selectedPatient === -1
        && <StudyDetail
          studies={props.studies}
          setStudies={props.setStudies}
          windowSize={props.windowSize}
          token={props.token}
        />
      }
      {props.studies.selectedStudy > -1 && props.studies.selectedPatient > -1
        && <PatientDetail
          token={props.token}
          studies = {props.studies}
          setStudies = {props.setStudies}
          windowSize={props.windowSize}
        />
      }
    </main>
  )
}

export default Content 
