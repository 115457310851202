
function prepareLinePlots(stream) {

    var ActInd_Data = []
    var timeArray = []

    for (let index = 0; index < stream.length; index++) {
        let ActInd = stream[index]
        let startdate = ActInd.StartDate
        ActInd.Data.forEach((element,i) => {
            if (i===0 || i===(ActInd.Data.length-1)) {
            ActInd_Data.push([-1, startdate+i]) 
            } else {
            ActInd_Data.push([element, startdate+i]) 
            }
            timeArray.push(startdate+i)  
        });
    }
    timeArray.sort()

    ActInd_Data.sort(function(a, b){
        let comparison = 0;
        if (a[1] > b[1]) {
          comparison = 1;
        } else if (a[1] < b[1]) {
          comparison = -1;
        }
        return comparison;
          })

    var yaxis=[]
    for (let index = 0; index < ActInd_Data.length; index++){
        yaxis[index]=ActInd_Data[index][0]
    }
    return({xaxis:timeArray,yaxis:yaxis})
}
export default prepareLinePlots
