import { makeStyles } from '@material-ui/core/styles'
import appConfig from '../config/appConfig'

const studyGridStyle = makeStyles((theme) => ({
  root: {
    flexGrow: 0,
    marginTop: 40,
  },
  grid: {
    padding: theme.spacing(3),
  },
  paper: {
    height: 240,
    width: 300,
    boxShadow: 'none',
    borderRadius: '5px 5px 0px 0px',
    backgroundColor: appConfig.colors.secondaryColor,
  },
  paperTitle: {
    position:'relative',
    height: 60,
    top:-1,
    width: 300,
    boxShadow: 'none',
    borderRadius: '0px 0px 5px 5px',
  },
  title: {
    fontSize: theme.typography.pxToRem(14),
    textAlign: 'center',
    fontWeight: 500,
    marginTop:10,
  },
  status: {
    fontSize: theme.typography.pxToRem(12),
    textAlign: 'center',
    fontWeight: 500,
    marginTop:2.5,
  },
  icon: {
    width:30,
    marginTop:10,
    marginLeft:20,
    verticalAlign: 'middle',
  },
  button: {
    margin: 125,
    color: '#707070',
    backgroundColor: '#ffffff',
    border: `1px solid #bbbbbb`,
    '&:hover': {
        color: '#FFFFFF',
        backgroundColor: appConfig.colors.blue,
        border: `1px solid ${appConfig.colors.blue}`,
    }
},
}))

export default studyGridStyle