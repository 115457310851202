import React, { useState, useEffect } from 'react'
import { Auth } from 'aws-amplify'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import logo from 'files/logo.png'
import appBarStyle from 'style/appBarStyle'

function Appbar() {
  const classes = appBarStyle() 
  const [userName, setUserName] = useState("")

  useEffect(() => {
    Auth.currentUserInfo().then(response => {setUserName(response.username)})
  }, [])

  const handleLogoutClick = () => {Auth.signOut()}

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <img className={classes.image} src={logo} alt="logo"/>
          <Typography variant="h6" noWrap className={classes.title}>
            Byteflies Scope
          </Typography>
          <Typography noWrap className={classes.userName}>
            {userName}
          </Typography>
          <IconButton color="inherit" onClick={handleLogoutClick}>
            <ExitToAppIcon/>
          </IconButton>
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default Appbar