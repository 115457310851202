import { makeStyles } from '@material-ui/core/styles'

const patientDetailHeaderStyle = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: 20,
    },
    title: {
        fontWeight: 500,
        fontSize: theme.typography.pxToRem(20)
    },
    subtitle: {
        fontWeight: 500,
        fontSize: theme.typography.pxToRem(12),
    }, 
}))

export default patientDetailHeaderStyle