import { makeStyles } from '@material-ui/core/styles'

const recordingListStyle = makeStyles((theme) => ({
    title: {
        fontSize: theme.typography.pxToRem(12),
        marginTop: 30,
        marginLeft: 20,
        marginBottom: 20,
    },
    heading: {
        fontSize: theme.typography.pxToRem(13),
        flexBasis: '35%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(12),
        color: theme.palette.text.secondary,
        flexBasis: '30%',
        flexShrink: 0,
    },
    table: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
        minWidth: 200,
    }
}))

export default recordingListStyle