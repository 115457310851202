import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'

import Amplify from 'aws-amplify'
// Get the aws resources configuration parameters

Amplify.configure({
    Auth: {
      region: process.env.REACT_APP_COGNITO_REGION,
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
      authenticationFlowType: "USER_PASSWORD_AUTH",
      mandatorySignIn: true,
    },
  })

ReactDOM.render(<App />, document.getElementById('root'))