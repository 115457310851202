import { Auth } from 'aws-amplify'

function authenticate() {
    return new Promise((resolve, reject) => {
        Auth.currentSession().then(
            response => {
                resolve(response.getIdToken().getJwtToken())
            })
    })
}

export default authenticate
