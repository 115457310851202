import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Switch from '@material-ui/core/Switch'
import Slider from '@material-ui/core/Slider'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import patientDetailHeaderStyle from 'style/patientDetailHeaderStyle'
import deletePatient from 'functions/bff/deletePatient'
import fetchStudyList from 'functions/bff/fetchStudyList'

function PatientDetailHeader(props) {
    const classes = patientDetailHeaderStyle()
    const startDate = new Date(props.patient.start * 1000).toString().slice(0, 15)
    const stopDate = new Date(props.patient.stop * 1000).toString().slice(0, 15)
    const [openMenu, setOpenMenu] = useState(null)

    const handleMenu = (menuItem) => async () => {
        if (menuItem === 'delete') {
            await deletePatient(props.patient,props.token)
            let data = await fetchStudyList(props.token)
            props.setStudies({ ...props.studies, studyList: data.studies, selectedPatient: -1 })
        }
        if (menuItem === 'modify'){

        }
    }

    return (
        <div style={{ height: 60 }}>
            <Typography className={classes.title}>
                {props.patient.id}
            </Typography>
            <Typography className={classes.subtitle}>
                {`${startDate} - ${stopDate}`}
            </Typography>
            <div style={{
                fontWeight: 500,
                fontSize: 12,
                position: 'relative',
                top: -20,
                width: 175,
                left: props.windowSize.width - 215,
                textAlign: 'right',
            }}>
                <Switch color='default' size="small" checked={props.patientView.boxplot.showEmpty} onChange={props.handleShowEmpty} />
                Show empty timeframes
            </div>
            <div style={{
                position: 'relative',
                top: -47,
                width: 100,
                left: props.windowSize.width - 400,
                textAlign: 'right',
            }}>
                <Slider
                    defaultValue={12}
                    valueLabelDisplay="auto"
                    step={4}
                    marks
                    min={4}
                    max={24}
                    onChange={props.handleBoxplotAmount}
                />
            </div>
            <div style={{
                fontWeight: 500,
                fontSize: 12,
                position: 'relative',
                top: -74,
                width: 50,
                left: props.windowSize.width - 290,
                textAlign: 'right',
            }}>
                Boxplots
            </div>
            <div style={{
                position: 'relative',
                top: -135,
                width: 20,
                left: props.windowSize.width - 75,
                textAlign: 'right',
            }}>
                <IconButton onClick={(event) => { setOpenMenu(event.currentTarget) }}>
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="simple-menu"
                    anchorEl={openMenu}
                    keepMounted
                    open={Boolean(openMenu)}
                    onClose={(event) => { setOpenMenu(null) }}
                >
                    <MenuItem onClick={handleMenu('delete')}>Delete Patient</MenuItem>
                    <MenuItem onClick={handleMenu('modify')}>Modify Patient</MenuItem>
                </Menu>
            </div>

        </div>
    )
}

export default PatientDetailHeader
