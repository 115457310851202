import React from 'react'
import Plot from 'react-plotly.js'
import Typography from '@material-ui/core/Typography'
import appConfig from 'config/appConfig'

function boxPlot(boxplots,graph, rangetop, handleClick, handleHover) {
    var traces = []
    const range = [0,rangetop]
    boxplots.streams.forEach(stream => {
    var trace = {
        y: stream,
        type: 'box',
        boxpoints: 'Outliers',
        fillcolor : appConfig.colors.secondaryColor,
        marker: {
           size: 1,
        },
        line: {
            width: 2,
            color: appConfig.colors.primaryColor,
        },
        hoverinfo:'none',
      }
      traces.push(trace)
    })

    var yAxis = []
    for (let i=0; i<graph.yGrid;i++){
        yAxis[i] = Math.round(range[1]/graph.yGrid*i)
    }

    return (
        <div>
        <Plot 
            data={traces}
            style={{position: 'absolute', marginLeft:0}}
            config={{
                displayModeBar: false,
                //staticPlot: true,
            }}
            onClick={handleClick}
            onHover={handleHover}
            // onUnhover={() => console.debug("onUnhover")}
            layout={{
                margin: {
                    l: 0,
                    r: 0,
                    t: 0,
                    b: 0
                },
                height: (graph.height-50)/2,
                width: graph.width-100,
                autosize: true,
                showlegend: false,
                //hovermode: true,
                paper_bgcolor: 'transparent',
                plot_bgcolor: 'transparent',
                xaxis: {
                    showgrid: true,
                    zeroline: false,
                    showline: false,
                    visible: false,
                },
                yaxis: {
                    showgrid: true,
                    dtick: 10,
                    zeroline: false,
                    showline: false,
                    visible: false,
                    range: range,
                },
            }} 
        />
        {yAxis.map((y,i) => (
            <Typography key={`y${y}`} style={{position:'absolute', width:20, top: (graph.height-50)-i*(graph.height-50)/graph.yGrid-10, left: -25,  fontSize: 12, textAlign: 'right'}}>{y}</Typography>
        ))}
    </div>
    )
}

export default boxPlot