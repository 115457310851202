import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import PersonIcon from '@material-ui/icons/Person'
import patientGridStyle from 'style/patientGridStyle'
import AddPatientDialog from './PatientGrid/AddPatientDialog'

function PatientGrid(props) {
    const classes = patientGridStyle()
    const [openDialog, setOpenDialog] = useState(false)

    const handlePatientSelect = (patient) => (event) => {
        props.setStudies({ ...props.studies, selectedPatient: patient })
    }
    
    return (
        <div>
            <Paper variant="outlined" className={classes.paperPatients}>
                <Grid container style={{ maxHeight: props.windowSize.height - 420 }} className={classes.root} spacing={3}>
                    {props.study.patients.map((patient, i) => (
                        <Grid key={`patientGrid${i}`} item onClick={handlePatientSelect(i)}>
                            <IconButton className={classes.button}>
                                <PersonIcon />
                                <Typography className={classes.title}>
                                    {patient.id}
                                </Typography>
                            </IconButton>
                        </Grid>
                    ))}
                    <Grid key='addPatient' item >
                        <IconButton className={classes.newButton} onClick={()=>{setOpenDialog(true)}}>
                            <PersonAddIcon />
                            <Typography className={classes.title}>
                                Add a new patient
                        </Typography>
                        </IconButton>
                    </Grid>
                </Grid>
            </Paper>
            <AddPatientDialog 
                open={openDialog} 
                setOpen={setOpenDialog}
                studies={props.studies}
                setStudies={props.setStudies}
                study={props.study}
                token={props.token}
            />
        </div >
    )
}

export default PatientGrid
