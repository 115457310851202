import appConfig from '../config/appConfig'

const statusColor = (status) => {
    switch (status){
        case 'FINISHED':
            return(appConfig.plotColors.blueDot)
        case 'ONGOING':
            return(appConfig.plotColors.greenDot)
        case 'PREPARING':
            return(appConfig.plotColors.orangeDot)
        case 'PLANNED':
            return(appConfig.plotColors.redDot)
        default:
            return(appConfig.colors.primaryColor)
    }
}

export default statusColor