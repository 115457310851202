import React, { useEffect, useState } from 'react'
import patientPlotStyle from 'style/patientBoxplotStyle'
import fetchStreamAI from 'functions/bff/fetchStreamAI'
import prepareLinePlots from 'functions/plot/prepareLinePlots'
import prepareAIBoxPlots from 'functions/plot/prepareAIBoxPlots'
import LinePlot from 'functions/plot/LinePlot'
import boxPlot from 'functions/plot/boxPlot'
import gridBox from 'functions/gridBox'

function PatientLineplot(props) {
    const classes = patientPlotStyle()
    const [lineplots, setLineplots] = useState([])
    const [RangeTop, setRangeTop] = useState(60)
    const [AIBoxplots, setAIBoxplots] = useState([])
    const [graphProps, setGraphProps] = useState([])
    const [viewRawData, setviewRawData] = useState(0)

    const handleBoxPlotClick = (event) => {
        var boxplotTrace = parseInt(event.points[0].x.slice(6,7))
        var boxplotData = AIBoxplots.streams[boxplotTrace]
        var startTime = AIBoxplots.StartTimes[boxplotTrace]
        var stream = {Data: boxplotData, StartDate : startTime}
        setLineplots(prepareLinePlots([stream]))
        setviewRawData(1)
    }

    const handleBoxPlotHover = (event) => {
    }    

    useEffect(() => {
        if (props.recordings.length) {
            async function fetchStreams() {
                let stream = []
                for (let index = 0; index < props.recordings.length; index++) {
                    let recording = props.recordings[index]
                    let startDate = props.recordings[index].startDate
                    let fetch = await fetchStreamAI({ group: props.patient.group, recording: recording.id, signal: 'AI0', start: 0, end: 0 }, props.token)
                    let AI_dict = {Data: fetch, StartDate : startDate}
                    stream.push(AI_dict)
                }
                setAIBoxplots(prepareAIBoxPlots(stream, props.patient, props.patientView.boxplot))
            }
            fetchStreams()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.recordings, props.patientView])

    useEffect(() => {
        if (AIBoxplots.streams) {
            setGraphProps({ 
                height: props.graphSize.height,
                width: props.graphSize.width,
                xGrid: AIBoxplots.streams.length,
                yGrid: Math.round(props.graphSize.height / props.graphSize.width * AIBoxplots.streams.length),
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [AIBoxplots, props.graphSize])

    useEffect(() => {
        setviewRawData(0)
    }, [props.patientView])

    return (
        <div className={classes.root}>
            {graphProps.length !== 0 && viewRawData === 0
                && <div>
                    {gridBox(AIBoxplots, graphProps)}
                    {boxPlot(AIBoxplots, graphProps, RangeTop, handleBoxPlotClick, handleBoxPlotHover)}
                </div>
            }
            {graphProps.length !== 0 && viewRawData !== 0
                && <div>
                    {LinePlot(lineplots, graphProps, RangeTop)}
                </div>
            }
        </div>
    )
}

export default PatientLineplot
