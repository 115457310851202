import React, { useState } from 'react'
import clsx from 'clsx'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import drawerLeftStyle from 'style/drawerLeftStyle'
import StudyList from './DrawerLeft/StudyList'
import RecordingList from './DrawerLeft/RecordingList'

function DrawerLeft(props) {
  const classes = drawerLeftStyle()
  const [buttonEnable, setButtonEnable] = useState(false)

  const onMouseEnterHandler = () => {
    setButtonEnable(true)
  }
  
  const onMouseLeaveHandler = () => {
    setButtonEnable(false)
  }

  const returnButton = () => {
    if (buttonEnable || !props.windowSize.open) {
      return (
        <IconButton 
          className={clsx(classes.button, {
            [classes.buttonOpen]: props.windowSize.open,
            [classes.buttonClose]: !props.windowSize.open,
          })} 
          size='small' 
          aria-label="add" 
          onClick={props.drawerCallback} 
          onMouseEnter={onMouseEnterHandler} 
          onMouseLeave={onMouseLeaveHandler}>
          {props.windowSize.open ? <ChevronLeftIcon style={{ fontSize: 15, }} /> : <ChevronRightIcon style={{ fontSize: 15 }} />}
        </IconButton>
  )}}

  return (
    <div>
      {returnButton()}
      <Drawer
        variant="permanent"
        className={clsx(classes.drawerLeft, {
          [classes.open]: props.windowSize.open,
          [classes.close]: !props.windowSize.open,
        })}
        classes={{
          paper: clsx({
            [classes.open]: props.windowSize.open,
            [classes.close]: !props.windowSize.open,
          }),
        }}
        onMouseEnter={onMouseEnterHandler} onMouseLeave={onMouseLeaveHandler} >
        {props.windowSize.open ? (<div className={classes.root}>
          <StudyList
            studies = {props.studies}
            setStudies = {props.setStudies}
          />
          <RecordingList/>
        </div>):null}
      </Drawer>
    </div>
  )
}
export default DrawerLeft