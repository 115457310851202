export default {
    api_url: 'https://api.cloud.byteflies.net/',
    group: {
      id:"b9127380-7020-11ea-b37b-f3ab258be758",
      name:'6MWT',
    },
    recording: {
      id:"a18f0850-7033-11ea-a803-4fb72254b888",
    },
    ecg: ["ExG_ECG", "ExG_EEG", "ExG", "ECG"],
    acm: ["ACC"],
    peaks:  ["RPEAK"],

  }