
import React from 'react'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import studyDescriptionStyle from 'style/studyDescriptionStyle'
import studyInfo from 'functions/studyInfo'


function StudyDescription(props) {
    const classes = studyDescriptionStyle()

    return (
        <Paper variant="outlined" className={classes.paper}>
            <Grid container>
                <Grid xs item>
                    <Typography className={classes.descriptionTitle}>
                        study description
                </Typography>
                    <Typography className={classes.description}>
                        {props.study.description}
                    </Typography>
                </Grid>
                <Grid className={classes.studyInfo} item>
                   {studyInfo(props.study)}
                </Grid>
            </Grid>
        </Paper>
    )
}

export default StudyDescription
