import React, {useState} from 'react'
import clsx from 'clsx'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import statusColor from 'functions/statusColor'
import studyGridItemStyle from 'style/studyGridItemStyle'
import studyInfo from 'functions/studyInfo'

function StudyGridItem(props) {
    const classes = studyGridItemStyle()
    const [highlight, setHighlight] = useState(false)

    return (
        <div onMouseEnter={() => {setHighlight(true)}} onMouseLeave={() => {setHighlight(false)}}>
            <Paper 
                variant="outlined" 
                className={clsx(classes.paper, {
                    [classes.paperHighlight]: highlight,
                    [classes.paierNoHighlight]: !highlight,
                })}
            >
                {studyInfo(props.study)}
            </Paper>
            <Paper 
                variant="outlined" 
                className={clsx(classes.paperTitle, {
                    [classes.paperTitleHighlight]: highlight,
                    [classes.paierTitleNoHighlight]: !highlight,
                })}
            >
                <Typography className={classes.title}>
                    {props.study.title}
                </Typography>
                <Typography className={classes.status} style={{ color: statusColor(props.study.status) }}>
                    {props.study.status}
                </Typography>
            </Paper>
        </div>
    )
}

export default StudyGridItem
