function prepareBoxPlots(stream,patient,boxplot) {
    stream.sort()
    
    var boxplotStreams = []
    var boxplotNames = []
    var StartTimestamps = []
    const start = Math.floor(patient.start / boxplot.size) * boxplot.size
    for (let index = 1; index < boxplot.amount + 1; index++) {
        let split = stream.findIndex(element => element > start + boxplot.size * index)
        let boxplotStream = stream.splice(0, split)
        if(split===-1){
            boxplotStream=stream
            stream = []
        }

        for(let index2 = 0; index2<boxplotStream.length-1;index2++){
            boxplotStream[index2] = 60/(boxplotStream[index2+1]-boxplotStream[index2])
        }
        boxplotStream.pop()

        if(!boxplotStream.length && boxplot.showEmpty){
            boxplotStream.push(-1)
        }

        if(boxplotStream.length){
            boxplotStreams.push(boxplotStream)

            let time = new Date((start+boxplot.size*(index-0.5))*1000).toString()
            let boxplotName = time.slice(4,11).concat(time.slice(16,21))
            boxplotNames.push(boxplotName)
            StartTimestamps.push(start+boxplot.size*(index-1)*1000)
        }  
    }

    return({names:boxplotNames,streams:boxplotStreams, StartTimes : StartTimestamps})
}

export default prepareBoxPlots
