import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import statusColor from 'functions/statusColor'
import studyDetailStyle from 'style/studyDetailStyle'
import deleteStudy from 'functions/bff/deleteStudy'
import fetchStudyList from 'functions/bff/fetchStudyList'
import StudyDescription from './StudyDetail/StudyDescription'
import PatientGrid from './StudyDetail/PatientGrid'

function StudyDetail(props) {
    const classes = studyDetailStyle()
    const [openMenu, setOpenMenu] = useState(null)
    const [study, setStudy] = useState(props.studies.studyList[props.studies.selectedStudy])

    const handleMenu = (menuItem) => async () => {
        if (menuItem === 'delete') {
            await deleteStudy(study,props.token)
            let data = await fetchStudyList(props.token)
            props.setStudies({ ...props.studies, studyList: data.studies, selectedStudy: -1 })
        }
        if (menuItem === 'modify') {

        }
    }

    useEffect(() => {
        setStudy(props.studies.studyList[props.studies.selectedStudy])
    }, [props.studies])

    return (
        <div className={classes.root}>
            <Grid container spacing={2} justify="space-between">
                <Grid item>
                    <Typography className={classes.title}>
                        {study.title}
                    </Typography>
                    <Typography className={classes.subtitle} style={{ color: statusColor(study.status) }}>
                        {study.status}
                    </Typography>
                </Grid>
                <Grid item>
                    <IconButton onClick={(event) => { setOpenMenu(event.currentTarget) }}>
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id="simple-menu"
                        anchorEl={openMenu}
                        keepMounted
                        open={Boolean(openMenu)}
                        onClose={(event) => { setOpenMenu(null) }}
                    >
                        <MenuItem onClick={handleMenu('delete')}>Delete Study</MenuItem>
                        <MenuItem onClick={handleMenu('modify')}>Modify Study</MenuItem>
                    </Menu>
                </Grid>
            </Grid>

            <StudyDescription
                study={study}
            />
            <PatientGrid
                study={study}
                studies={props.studies}
                setStudies={props.setStudies}
                windowSize={props.windowSize}
                token={props.token}
            />
        </div>
    )
}

export default StudyDetail
