import { makeStyles } from '@material-ui/core/styles'
import appConfig from 'config/appConfig'

const addStudyDialogStyle = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    dialogButton: {
        color: appConfig.colors.blue,
    },
    dialogTextField: {
        color: appConfig.colors.blue,
        marginBottom: 20,
        marginRight: 40,
    },
    dialogIcon: {
        marginTop: 20,
        marginRight: 20,
        marginBottom: 20

    },
}))

export default addStudyDialogStyle