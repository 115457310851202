export default {


    colors:{
        primaryColor: '#020730',
        secondaryColor: '#f5f4f5',
        blue: '#207499',
    },
    plotColors:{
        redDot: '#b32024',
        blueDot: '#207499',
        greenDot: '#4fb348',
        orangeDot: '#b2842d',
        purpleDot: '#643C97',
    },
    plotSecundaryColors:{
        redDot: '#de6f72',
        blueDot: '#67bbe0',
        greenDot: '#96f090',
        orangeDot: '#f5ca7a',
        purpleDot: '#b088e3',
    },
    drawerWidth:{
        open: 240,
        close: 20,
    },
    gridNumber: 24,
    // scale: [0, 300, 60, 10, 5, 1],
    scale: [0, 150, 60, 10, 5, 1],


    signalName:{
        ECG:"electrocardiogram",
        RPEAK:"reaks",
        ACC:"accelerometer",
        GYR:"gyroscope",
        BPM:"heart rate",
        IBI:"rr distance",
        MOT:"motion",
        ACT:"activity"
    },

    signalConversion:{
        ECG: 0.00000048,
        RPEAK: 0.00000048,
        ACC: 1/4096,
        GYR: 1/4096,
        BPM: 1,
        IBI: 1,
        MOT: 1,
        ACT: 1,
    },
    viewFactor:{
        ECG: 2.75,
        RPEAK: 3,
        ACC: 3,
        GYR: 1,
        BPM: 1/20,
        IBI: 6,
        MOT: 2.5,
        ACT: 20,
    },
    viewOffset:{
        ECG: -0.75,
        RPEAK: -0.75,
        ACC: 0,
        GYR: 0,
        BPM: -120,
        IBI: -1,
        MOT: -3,
        ACT: -3,
    },
}