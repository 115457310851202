import React from 'react'
import Plot from 'react-plotly.js'
import Typography from '@material-ui/core/Typography'
import appConfig from 'config/appConfig'

function LinePlot(lineplots,graph, rangetop) { 
    const range = [0,rangetop]
    var trace = {
        x: lineplots.xaxis,
        y: lineplots.yaxis,
        type: 'scatter',
        mode: 'lines',
        hoverinfo:"none",
        marker: {
            size: 1,
            color: appConfig.colors.primaryColor
        },
      }  

      var yAxis = []
      for (let i=0; i<graph.yGrid;i++){
          yAxis[i] = Math.round(range[1]/graph.yGrid*i)
      }
    return (
        <div>
        <Plot 
            config={{
                displayModeBar: false,
                //staticPlot: true,
            }}
            data={[trace]}
            layout={{
                margin: {
                    l: 0,
                    r: 0,
                    t: 0,
                    b: 0
                },
                height: graph.height-50,
                width: graph.width-100,
                yaxis: {
                    showgrid: true,
                    dtick: 10,
                    zeroline: false,
                    showline: false,
                    visible: false,
                    range: range,
                },
                autosize: true,
                showlegend: false,
                paper_bgcolor: 'transparent',
                plot_bgcolor: 'transparent',
            }} 
        />
            {yAxis.map((y,i) => (
                <Typography key={`y${y}`} style={{position:'absolute', width:20, top: (graph.height-50)-i*(graph.height-50)/graph.yGrid-10, left: -25,  fontSize: 12, textAlign: 'right'}}>{y}</Typography>
            ))}
    </div>
    )
}

export default LinePlot