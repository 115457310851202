import { makeStyles } from '@material-ui/core/styles'
import appConfig from '../config/appConfig'

const appBarStyle = makeStyles((theme) => ({
    root: {
        marginTop: 80,
        marginLeft: 0,
        marginRight: 0,
    },
    drawerLeft: {
        width: appConfig.drawerWidth.open,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    open: {
        width: appConfig.drawerWidth.open,
        backgroundColor: appConfig.colors.secondaryColor,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    close: {
        backgroundColor: appConfig.colors.secondaryColor,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: appConfig.drawerWidth.close,
        },
    },
    button: {
        color: '#707070',
        backgroundColor: '#ffffff',
        border: `1px solid #bbbbbb`,
        position: 'absolute',
        top: 87,
        zIndex: 10000,
        '&:hover': {
            color: '#FFFFFF',
            backgroundColor: appConfig.colors.blue,
            border: `1px solid ${appConfig.colors.blue}`,
        }
    },
    buttonOpen: {
        left: appConfig.drawerWidth.open - 12,
        transition: theme.transitions.create('left', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    buttonClose: {
        left: appConfig.drawerWidth.close - 12,
        transition: theme.transitions.create('left', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
}))

export default appBarStyle