import { makeStyles } from '@material-ui/core/styles'

const patientDetailStyle = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: 20,
    },
    title: {
        fontWeight: 500,
        fontSize: theme.typography.pxToRem(20)
    },
    subtitle: {
        fontWeight: 500,
        fontSize: theme.typography.pxToRem(12),
    },
    paper1: {
        position: 'relative',
        boxShadow: 'none',
        borderRadius: '5px 5px 5px 5px',
        padding: 0,
        display: 'flex',
    },
    paper2: {
        position: 'absolute', 
        top: -1, 
        left: -1, 
        boxShadow: 'none',
        borderRadius: '0px 5px 0px 5px', 
        marginLeft: 100,
    },
}))

export default patientDetailStyle