import React from 'react'
import dots from 'files/dots.png'

const loadImage = function (windowSize) {

    return (
        <img
            style={{
                position: 'absolute',
                width: 250,
                height: 250,
                alignItems: 'center',
                marginLeft: windowSize.width / 2 - 125,
                marginTop: windowSize.height / 2 - 185,
            }}
            src={dots}
            alt="dots"
        />
    )
}

export default loadImage
