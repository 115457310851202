import axios from 'axios'
import cloudApi from 'config/cloudApi'

function listRecordings(groupId, auth,start,stop) {
    var url = ``
    if(start===undefined || stop===undefined){
         url = `${cloudApi.api_url}groups/${groupId}/recordings`
    }
    else{
         url = `${cloudApi.api_url}groups/${groupId}/recordings?begin=${start}&end=${stop}`
    }
    return new Promise((resolve, reject) => {
        axios.get(
            url,
            { headers: { 'Authorization': auth } }
        ).then(
            response => {
                const recordings = response.data.map(rec => ({
                    id: rec.id,
                    dot: rec.dotId,
                    dock: rec.dockName,
                    startDate: rec.startDate,
                    duration: rec.duration,
                    signals: rec.signals.map(s => (s.type)),
                }))
                resolve(recordings)
            })
    })

}

export default listRecordings
