import listRecordings from './cloud/listRecordings'
 

function listPatientRecordings(token, patient) {
    return new Promise((resolve, reject) =>{
        listRecordings(patient.group,token,patient.start,patient.stop).then(response => {
            var recordings = response.filter(recording => recording.dock === patient.dock && recording.duration > 300)
            resolve(recordings)
        })
    })
}
export default listPatientRecordings
