import dataApi from 'config/dataApi'

function fetchStudyList(token) {
    if(dataApi.api_url == null){
        throw 'Api url should be defined'
    }
    const url = `${dataApi.api_url}studies`
    return new Promise((resolve, reject) => {
        fetch(url, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': token,
            },
        }).then(response => response.json().then(data => {
            resolve(data)
        }))
    })
}
export default fetchStudyList
