import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import addPatientDialogStyle from 'style/addPatientDialogStyle'
import addPatient from 'functions/bff/addPatient'
import fetchStudyList from 'functions/bff/fetchStudyList'
import DockIcon from 'icons/DockIcon'

function AddPatientDialog(props) {
    const classes = addPatientDialogStyle()
    const [patientID, setPatientID] = useState('')
    const [dockID, setDockID] = useState('')

    const handleAddPatient = async () => {
        props.setOpen(false)

        if (patientID !== '') {
            //let now = new Date().getTime()
            var patient = {
                id: patientID,
                study_id: props.study.id,
                dock: 'Dock-6c21a28eb38a',
                group: 'b9127380-7020-11ea-b37b-f3ab258be758',
                start: '1591252200',
                stop: '1591440000',
            }
            await addPatient(patient,props.token)
            let data = await fetchStudyList(props.token)
            props.setStudies({ ...props.studies, studyList: data.studies })
        }
    }

    return (
        <Dialog open={props.open} onClose={()=>{props.setOpen(true)}}>
            <DialogTitle id="form-dialog-title">Add a new patient</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    To add a new patient to your study, please enter a unique, unidentified patientId, which will be used to link your patient the Sensor Dot database.
                    </DialogContentText>
                <Grid container spacing={1} justify="space-between">
                    <Grid item xs>
                        <Grid container spacing={2} alignItems="flex-end">
                            <Grid item>
                                <PersonAddIcon />
                            </Grid>
                            <Grid item>
                                <TextField style={{ minWidth: 180 }} autoComplete="off" id="patientID" label="patientID" type="patientID" onChange={event => setPatientID(event.target.value)} InputLabelProps={{ shrink: true, }} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs>
                        <Grid container spacing={2} alignItems="flex-end">
                            <Grid item>
                                <DockIcon />
                            </Grid>
                            <Grid item>
                                <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                                    dockID
                                    </InputLabel>
                                <Select style={{ minWidth: 180 }} labelId="dockID" id="dockID" value={dockID} onChange={event => setDockID(event.target.value)} >
                                    <MenuItem value={1}>Dock-18937f780118</MenuItem>
                                    <MenuItem value={2}>Dock-18937f778a0e</MenuItem>
                                    <MenuItem value={3}>Dock-6c21a28eb38a</MenuItem>
                                    <MenuItem value={3}>Dock-18937f774920</MenuItem>
                                    <MenuItem value={3}>Dock-6c21a2bf1872</MenuItem>
                                </Select>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>{props.setOpen(false)}} className={classes.dialogButton}>
                    Cancel
                    </Button>
                <Button onClick={handleAddPatient} className={classes.dialogButton}>
                    Add
                    </Button>
            </DialogActions>
        </Dialog >
    )
}

export default AddPatientDialog
