import React, { useState, useEffect } from 'react'
import Paper from '@material-ui/core/Paper'
import patientDetailStyle from 'style/patientDetailStyle'
import listPatientRecordings from 'functions/listPatientRecordings'
import PatientDetailHeader from './PatientDetail/PatientDetailHeader'
import PatientBoxplot from './PatientDetail/PatientBoxplot'
import PatientLineplot from './PatientDetail/PatientLineplot'


function PatientDetail(props) {
    const classes = patientDetailStyle()
    const [patient, setPatient] = useState(0)
    const [patientView, setPatientView] = useState({
        selectedView: 'boxplot',
        boxplot: {
            amount: 20,
            size: 0,
            showEmpty:true,
        }
    })
    const [patientRecordings, setPatientRecordings] = useState([])
    const [graphSize, setGraphSize] = useState({width:0,height:0})
    
    const handleShowEmpty = () => {
        setPatientView({...patientView, boxplot: {...patientView.boxplot, showEmpty: !patientView.boxplot.showEmpty}})
    }

    const handleBoxplotAmount = (event,newValue) => {
        setPatientView({...patientView, boxplot: {...patientView.boxplot, amount: newValue, size: Math.floor((patient.stop - patient.start) / newValue / 300) * 300}})
    }

    useEffect(() => {
        setPatient(props.studies.studyList[props.studies.selectedStudy].patients[props.studies.selectedPatient])
        setGraphSize({width:props.windowSize.width-60, height:(props.windowSize.height-164)/2})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.studies.selectedPatient,props.windowSize])

    useEffect(() => {
       if(patient){
           if(props.token) {
               listPatientRecordings(props.token,patient).then(recordings => setPatientRecordings(recordings))
           }
           setPatientView({...patientView, boxplot: {...patientView.boxplot, size: Math.floor((patient.stop - patient.start) / 20 / 300) * 300, showEmpty: true}})
       }
       // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patient])
    
    return (
        <div className={classes.root}>
            <PatientDetailHeader 
                patient={patient} 
                patientView={patientView}
                windowSize = {props.windowSize}
                setStudies = {props.setStudies}
                studies = {props.studies}
                handleShowEmpty = {handleShowEmpty}
                handleBoxplotAmount = {handleBoxplotAmount}
                token={props.token}
            />
            <Paper className={classes.paper1} variant='outlined' style={{height: graphSize.height, width: graphSize.width }}>
                <Paper className={classes.paper2} variant='outlined' style={{height: graphSize.height-50, width: graphSize.width-100}}>
                {patientView.selectedView === 'boxplot'
                    && <PatientBoxplot 
                        token={props.token}
                        patient={patient} 
                        patientView={patientView}
                        setPatientView = {setPatientView}
                        recordings={patientRecordings}
                        graphSize={graphSize}
                    /> 
                }
                </Paper>
            </Paper>
            <Paper className={classes.paper1} variant='outlined' style={{height: graphSize.height, width: graphSize.width }}>
                <Paper className={classes.paper2} variant='outlined' style={{height: graphSize.height-50, width: graphSize.width-100}}>
                {patientView.selectedView === 'boxplot'
                    && <PatientLineplot
                        token={props.token}
                        patient={patient} 
                        patientView={patientView}
                        setPatientView = {setPatientView}
                        recordings={patientRecordings}
                        graphSize={graphSize}
                    />
                }
                </Paper>
            </Paper>
        </div>
    )
}

export default PatientDetail
