
function prepareLinePlots(stream,patient,boxplot) {

    var ActInd_Data = []
    var timeArray = []
    var boxplotStreams = []
    var boxplotNames = []
    var StartTimestamps = [] 
    var yaxis = []

    for (let index = 0; index < stream.length; index++) {
        let ActInd = stream[index]
        let startdate = ActInd.StartDate
        ActInd.Data.forEach((element,i) => {
            ActInd_Data.push([element, startdate+i])         
            timeArray.push(startdate+i)  
        });
    }
    timeArray.sort()
    ActInd_Data.sort(function(a, b){
        let comparison = 0;
        if (a[1] > b[1]) {
          comparison = 1;
        } else if (a[1] < b[1]) {
          comparison = -1;
        }
        return comparison;
    })

    for (let index = 0; index < ActInd_Data.length; index++){
        yaxis[index]=ActInd_Data[index][0]
    }

    const start = Math.floor(patient.start / boxplot.size) * boxplot.size
    for (let index = 1; index < boxplot.amount + 1; index++) {
        let split = timeArray.findIndex(element => element > start + boxplot.size * index)
        let boxplotStream = yaxis.splice(0, split)
        timeArray.splice(0, split)

        if (split===-1) {
            boxplotStream=yaxis
            yaxis = []
        }

        if (!boxplotStream.length && boxplot.showEmpty) {
            boxplotStream.push(-1)
        }

        if(boxplotStream.length){
            boxplotStreams.push(boxplotStream)
            let timeName = new Date((start+boxplot.size*(index-0.5))*1000).toString()
            let boxplotName = timeName.slice(4,11).concat(timeName.slice(16,21))
            boxplotNames.push(boxplotName)
            StartTimestamps.push(start+boxplot.size*(index-1)*1000)
        }  
    }

    return({names:boxplotNames,streams:boxplotStreams, StartTimes : StartTimestamps})
}
export default prepareLinePlots
