import React from 'react'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Container from '@material-ui/core/Container'
import studyListStyle from 'style/studyListStyle'
import StudyBreadCrumbs from './StudyList/StudyBreadcrumbs'

function StudyList(props) {
  const classes = studyListStyle()

  const handleClickStudy = (study) => (event) => {
    if (study === props.studies.selectedStudy){study=-1}
    props.setStudies({...props.studies, selectedStudy: study, selectedPatient: -1})
  }
  
  const handleClickPatient = (patient) => (event) => {
    if (patient === props.studies.setSelectedPatient){patient=-1}
    props.setStudies({...props.studies, selectedPatient: patient})
  }

  return (
    <div>
      <StudyBreadCrumbs 
        studies={props.studies} 
        setStudies = {props.setStudies}
      />
      <Container className={classes.listContainer}>
        <List>
          {props.studies.studyList.map((study, i) =>
            <div key={`study${i}`}>
              <ListItem className={classes.listItem} button onClick={handleClickStudy(i)} >
                <Typography className={classes.listItemStudy}>
                  {study.title}
                </Typography>
              </ListItem>
              {props.studies.selectedStudy === i ? (
                <List disablePadding >
                  {study.patients.map((patient, j) =>
                    <ListItem button key={`study${i}patient${j}`} onClick={handleClickPatient(j)}>
                      <Typography className={classes.listItemPatient}>
                        {patient.id}
                      </Typography>
                    </ListItem>
                  )}
                </List>
              ) : null}
            </div>
          )}
        </List>
      </Container>
    </div>
  )
}

export default StudyList