import { makeStyles } from '@material-ui/core/styles'
import appConfig from '../config/appConfig'

const studyListStyle = makeStyles((theme) => ({
    title: {
        fontSize: theme.typography.pxToRem(12),
        marginTop: 10,
        marginLeft: 20,
        marginBottom: 10,
    },
    breadcrumbs: {
        fontSize: theme.typography.pxToRem(12),
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 25,
        position: 'static', 
        top: 0, 
        height: 20, 
        width: appConfig.drawerWidth.open, 
        backgroundColor: appConfig.colors.secondaryColor,
    },
    breadcrumbFinal: {
        fontSize: theme.typography.pxToRem(12),
        color: '#000000',
    },
    listContainer: {
        left:-25,
        width: '100vh', 
        maxHeight: window.innerHeight-190, 
        position: 'absolute', 
        overflow: 'auto',     
    },
    listItem: {
        marginBottom: 5,
    },
    listItemStudy: {
        fontSize: theme.typography.pxToRem(14),
        marginLeft: 10,
    },
    listItemPatient: {
        fontSize: theme.typography.pxToRem(14),
        marginLeft: 30,
    },
    heading: {
        fontSize: theme.typography.pxToRem(13),
        flexBasis: '35%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(12),
        color: theme.palette.text.secondary,
        flexBasis: '30%',
        flexShrink: 0,
    },
    table: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
        minWidth: 200,
    }
}))

export default studyListStyle